import { useState } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Store from 'store';
import { v4 as uuid } from 'uuid';

import { analyticsTrack } from '@pumpkincare/analytics';
import { useMarketingAttribution } from '@pumpkincare/marketing';
import { CjPixel, TunePixel } from '@pumpkincare/marketing/ui';
import {
  getQuoteActivePet,
  getQuotePolicyState,
  getQuotePolicyZipCode,
  getQuoteVetId,
  useQuote,
  useQuotesActiveId,
} from '@pumpkincare/quotes';
import {
  COMPLETE_QUOTE_ID_LS_KEY,
  GRAND_TOTAL_LOCAL_STORAGE_KEY,
  useTargetState,
} from '@pumpkincare/shared';
import { Body1, Chip, LoaderButton, TextField } from '@pumpkincare/shared/ui';
import { VetClinic } from '@pumpkincare/vets/ui';

import PepUpsell from './pep-upsell/pep-upsell';
import HdyhauLegacy from './hdyhau-legacy';
import PostCheckoutWellnessUpsell from './post-checkout-wellness-upsell';
import useSubmitHdyhau from './use-submit-hdyhau';

import styles from './hdyhau.css';

const MY_VET_CLINIC = 'MY_VET_CLINIC';
const OTHER = 'OTHER';
const OPTIONS = [
  { value: MY_VET_CLINIC, label: 'My Vet or Clinic Staff' },
  { value: 'CUSTOMER_REVIEWS', label: 'Reading Customer Reviews' },
  { value: 'PET_INSURANCE_REVIEW_SITE', label: 'Pet Insurance Comparison Site' },
  { value: 'GOOGLE_OR_AD', label: 'Online Search for Insurance' },
  { value: 'TELEVISION', label: 'TV or Streaming' },
  { value: 'FRIEND_OR_FAMILY', label: 'Friend or Family' },
  { value: 'BREEDER', label: 'Breeder' },
  { value: 'SHELTER', label: 'Shelter' },
  { value: 'SOCIAL_MEDIA', label: 'Social Media' },
  { value: 'MY_EMPLOYER', label: 'My Employer' },
  { value: OTHER, label: 'Other' },
];

function Hdyhau() {
  const { punks2110CaliforniaModelLaw, mvx218MultiTouchHdyhau } = useFlags();

  const grandTotal = Store.get(GRAND_TOTAL_LOCAL_STORAGE_KEY);
  const quoteId = Store.get(COMPLETE_QUOTE_ID_LS_KEY);

  const { data: quoteData } = useQuote({ quoteId });
  const quoteZipCode = getQuotePolicyZipCode(quoteData);
  const vetId = getQuoteVetId(quoteData);

  const [hdyhauOptionSelected, setHdyhauOptionSelected] = useState(null);
  const [hdyhauOptionsSelected, setHdyhauOptionsSelected] = useState({});
  const [selectedVet, setSelectedVet] = useState(null);
  const [otherText, setOtherText] = useTargetState('');

  const { activeId } = useQuotesActiveId();
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const policyState = getQuotePolicyState(quoteData);
  const isCAModelLaw = punks2110CaliforniaModelLaw && policyState === 'CA';

  const { submit: submitHdyhau, isLoading } = useSubmitHdyhau({
    vetId,
    session: uuid(),
  });
  const isFirstVisitWithAttribution = useMarketingAttribution(
    quoteId,
    'Land on Checkout Success',
    'isGoingToCheckoutSuccess'
  );
  const isMyVetClinic =
    hdyhauOptionSelected === MY_VET_CLINIC || hdyhauOptionsSelected[MY_VET_CLINIC];
  const isOtherSelected = mvx218MultiTouchHdyhau && hdyhauOptionsSelected[OTHER];

  function isCtaDisabledLegacy() {
    if (isMyVetClinic) {
      return !selectedVet || !selectedVet.valid;
    }

    return mvx218MultiTouchHdyhau
      ? !Object.keys(hdyhauOptionsSelected).some(
          option => hdyhauOptionsSelected[option]
        )
      : !hdyhauOptionSelected;
  }

  function isCtaDisabled() {
    if (isOtherSelected && !otherText) return true;
    if (isMyVetClinic && !selectedVet?.valid) return true;

    return !Object.keys(hdyhauOptionsSelected).some(
      option => hdyhauOptionsSelected[option]
    );
  }

  function handleChipClick(option) {
    setHdyhauOptionsSelected(state => {
      return {
        ...state,
        [option]: !state[option],
      };
    });
  }

  function handleSubmit() {
    const payload = Object.keys(hdyhauOptionsSelected)
      .filter(selection => hdyhauOptionsSelected[selection])
      .map(selection => {
        const optionIndex = OPTIONS.findIndex(option => {
          return option.value === selection;
        });
        const { label } = OPTIONS[optionIndex];

        analyticsTrack({
          category: 'Vet Form',
          event: 'Submit HDYHAU',
          answer: label,
          order: optionIndex,
        });

        const base = {
          option_identifier: selection,
          option_value: label,
          order: optionIndex,
        };

        if (selection === OTHER) {
          return { ...base, other: otherText };
        }

        if (selection === MY_VET_CLINIC && selectedVet?.existing?.id) {
          return { ...base, vet_id: selectedVet.existing.id };
        }

        return base;
      });

    submitHdyhau(payload);
  }

  return (
    <div
      className={classNames(styles.mainContainer, {
        [styles.multiTouch]: mvx218MultiTouchHdyhau,
      })}
    >
      {mvx218MultiTouchHdyhau ? (
        <h2>
          Welcome to Pumpkin! <br />
          Just a few questions before account setup
        </h2>
      ) : (
        <h1 className={styles.header}>Yay, you’re enrolled!</h1>
      )}

      <div className={styles.container}>
        <div className={styles.leftSide}>
          {mvx218MultiTouchHdyhau ? (
            <div className={styles.form}>
              <Body1 isBold className={styles.subtitle}>
                How did you hear about Pumpkin?
              </Body1>
              <Body1 isBold className={styles.subtitle}>
                Select all that apply
              </Body1>

              <div className={styles.options}>
                {OPTIONS.map(({ value, label }) => {
                  return (
                    <Chip
                      key={value}
                      selected={hdyhauOptionsSelected[value] || false}
                      onClick={() => handleChipClick(value)}
                      classes={{ root: styles.chip }}
                    >
                      {label}
                    </Chip>
                  );
                })}
              </div>

              <div className={styles.actions}>
                {isMyVetClinic ? (
                  <VetClinic
                    zipcode={quoteZipCode}
                    onChange={setSelectedVet}
                    className={styles.clinic}
                    label='Vet Clinic'
                    placeholder=''
                  />
                ) : null}

                {isOtherSelected ? (
                  <TextField
                    id='other'
                    label='Other'
                    value={otherText}
                    onChange={setOtherText}
                    classes={{ container: styles.other }}
                  />
                ) : null}

                <LoaderButton
                  color='primary'
                  disabled={isCtaDisabled()}
                  isLoading={isLoading}
                  onClick={handleSubmit}
                  classes={{ root: styles.submit }}
                >
                  Next
                </LoaderButton>
              </div>
            </div>
          ) : (
            <HdyhauLegacy
              quoteZipCode={quoteZipCode}
              isCTADisabled={isCtaDisabledLegacy()}
              selectedVet={selectedVet}
              handleVetSelect={setSelectedVet}
              hdyhauOptionSelected={hdyhauOptionSelected}
              setHdyhauOptionSelected={setHdyhauOptionSelected}
              vetId={vetId}
            />
          )}
        </div>

        <div className={styles.rightSide}>
          {isCAModelLaw ? (
            <PostCheckoutWellnessUpsell species={activePet?.petBreedSpecies} />
          ) : (
            <PepUpsell />
          )}
        </div>

        {isFirstVisitWithAttribution ? (
          <>
            <CjPixel page='Purchase' quoteId={quoteId} checkoutTotal={grandTotal} />
            <TunePixel page='Sale' quoteId={quoteId} checkoutTotal={grandTotal} />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Hdyhau;
