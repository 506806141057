import { useState } from 'react';

import { Body1, Body2, Chip, LoaderButton } from '@pumpkincare/shared/ui';

import useSubmitHdyhau from '../use-submit-hdyhau';

import styles from './hdyhau-pumpkin-now.css';

import pumpkinNow from './pumpkinnow.png';

const OPTIONS = [
  { key: 'YES', copy: 'Yes, it was a reason I purchased', order: 0 },
  {
    key: 'YES_BUT_NOT_WHY',
    copy: "Yes, but it's not a reason I purchased",
    order: 1,
  },
  { key: 'NO', copy: 'No, this is my first time hearing about it', order: 2 },
];

function HdyhauPumpkinNow() {
  const { submitPumpkinNow, isLoading } = useSubmitHdyhau();

  const [selection, setSelection] = useState({});

  function handleSubmit(e) {
    e.preventDefault();

    submitPumpkinNow({
      option_value: 'PUMPKIN_NOW',
      option_identifier: 'PUMPKIN_NOW',
      other: selection.key,
      order: selection.order,
    });
  }

  return (
    <form className={styles.root} onSubmit={handleSubmit}>
      <h2>
        Did you know Pumpkin could pay you <i>before</i> you pay your vet bill?
      </h2>

      <div className={styles.info}>
        <Body1>
          PumpkinNow™ is an urgent pay service from Pumpkin Pet Insurance designed
          to pay you before you pay the vet for covered critical care bills.
        </Body1>
        <img src={pumpkinNow} alt='' />
      </div>

      <div>
        <Body2 isBold>Have you heard of PumpkinNow before?</Body2>
        <div className={styles.options}>
          {OPTIONS.map(option => (
            <Chip
              key={option.key}
              selected={selection.key === option.key}
              onClick={() => setSelection(option)}
              type='button'
            >
              {option.copy}
            </Chip>
          ))}
        </div>
      </div>

      <LoaderButton disabled={!selection.key} isLoading={isLoading} color='primary'>
        Next
      </LoaderButton>
    </form>
  );
}

export default HdyhauPumpkinNow;
