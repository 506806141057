import { useCallback, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import PropTypes from 'prop-types';

import { analyticsTrack, errorBannerShow } from '@pumpkincare/analytics';
import { captureException, useBanners } from '@pumpkincare/shared';
import { ErrorPage } from '@pumpkincare/shared/pages';
import { ErrorBoundary, GlobalBanner } from '@pumpkincare/shared/ui';

import Footer from '../footer';
import NavBar from '../nav-bar';

function Page(props) {
  const {
    children,
    isFooterVisible = true,
    isNavBarVisible = true,
    onMount,
  } = props;

  const { banners, removeBanner } = useBanners();

  const handleBannerError = useCallback(banner => {
    analyticsTrack(errorBannerShow(banner));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    onMount?.();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <header>
        {isNavBarVisible ? (
          <NavBar />
        ) : (
          <div
            style={{
              width: '100%',
              height: '8px',
              background: 'var(--bluesCluesBlue)',
            }}
          />
        )}
      </header>

      {banners.map(banner => {
        return (
          <GlobalBanner
            key={banner.type + banner.icon}
            banner={banner}
            onClose={() => removeBanner(banner)}
            onError={handleBannerError}
          />
        );
      })}

      <ErrorBoundary itemRenderer={<ErrorPage />} onError={captureException}>
        <main>{children}</main>
      </ErrorBoundary>

      <footer>{isFooterVisible ? <Footer /> : null}</footer>
    </>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  isFooterVisible: PropTypes.bool,
  isNavBarVisible: PropTypes.bool,
  onMount: PropTypes.func,
};

export default Page;
