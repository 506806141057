import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  getQuoteActivePet,
  getQuoteEmail,
  getQuoteFirstName,
  getQuoteLastName,
  getQuotePolicyState,
  getQuotePolicyZipCode,
  useQuote,
  useQuotesActiveId,
} from '@pumpkincare/quotes';
import { getIsLoggedIn } from '@pumpkincare/shared';
import { Trustpilot } from '@pumpkincare/shared/ui';
import {
  getPaymentMethodLast4,
  getUserBillingAddress,
  isAddressComplete,
  usePayment,
} from '@pumpkincare/user';

import { MoneyBackGuarantee } from '../../../../plans';
import {
  setQuotesEmail,
  setQuotesFirstName,
  setQuotesLastName,
  setQuotesPolicyState,
  setQuotesPolicyZipCode,
  setUpdatedQuoteBillingField,
  setUpdatedQuoteField,
  setUpdatedQuoteShippingField,
} from '../../../../quotes';
import useShowPaperless from '../../../../quotes/hooks/use-show-paperless';
import {
  getQuotesBillingAddress,
  getQuotesShippingAddress,
  getShippingSameAsBilling,
} from '../../../../quotes/selectors';
import { useTotalsValue } from '../../checkout-provider';
import SslTrustIcon from '../../ssl-trust-icon';
import PlanReview from '../plan-review';
import PurchaseButton from '../purchase-button';
import BillingAddressForm from './billing-address-form';
import Paperless from './paperless';
import PaymentForm from './payment-form';
import ShippingAddressForm from './shipping-address-form';
import Terms from './terms';

import styles from './checkout-form.css';

function CheckoutForm({
  waitingPeriods,
  wellnessPets,
  setWellnessPets,
  addressData,
}) {
  const { punks1954UpsellSawToSawIns } = useFlags();
  const { data: quoteData } = useQuote();
  const quotesEmail = getQuoteEmail(quoteData);
  const quotesFirstName = getQuoteFirstName(quoteData);
  const quotesLastName = getQuoteLastName(quoteData);
  const quotesPolicyState = getQuotePolicyState(quoteData);
  const quotesPolicyZipCode = getQuotePolicyZipCode(quoteData);
  const { activeId } = useQuotesActiveId();
  const { data: paymentData } = usePayment();
  const last4 = getPaymentMethodLast4(paymentData);
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const dispatch = useDispatch();
  const billingAddress = useSelector(getQuotesBillingAddress);
  const shippingAddress = useSelector(getQuotesShippingAddress);
  const isShippingSameAsBilling = useSelector(getShippingSameAsBilling);

  const userBillingAddress = getUserBillingAddress(addressData);
  const isLoggedIn = getIsLoggedIn();
  const { grandTotal } = useTotalsValue();

  const showPaperless = useShowPaperless();

  const showBillingAddressAAP = punks1954UpsellSawToSawIns
    ? activePet?.existing_pet_id && !last4 && !isAddressComplete(userBillingAddress)
    : false;

  const showBillingAddress = isLoggedIn ? showBillingAddressAAP : true;

  const [isStickButton, setIsStickButton] = useState(false);
  const btnSectionRef = useRef();

  const buttonWrapperClass = classNames({
    [styles.fixedButtonWrapper]: isStickButton,
    [styles.buttonWrapper]: !isStickButton,
  });

  const sectionRefClass = classNames(styles.sectionRef, {
    [styles.sectionRefSticky]: isStickButton,
  });

  const buttonSectionClass = classNames({
    [styles.buttonSection]: !isStickButton,
    [styles.buttonSectionSticky]: isStickButton,
  });

  useEffect(() => {
    function handleScroll() {
      if (btnSectionRef.current) {
        const { offsetTop } = btnSectionRef.current;

        setIsStickButton(window.scrollY > offsetTop);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [btnSectionRef]);

  useEffect(() => {
    // TODO: remove once we no longer use Redux to validate the form fields
    dispatch(setQuotesEmail(quotesEmail));
    dispatch(setQuotesFirstName(quotesFirstName));
    dispatch(setQuotesLastName(quotesLastName));
    dispatch(setQuotesPolicyZipCode(quotesPolicyZipCode));
    dispatch(setQuotesPolicyState(quotesPolicyState));
  }, [
    dispatch,
    quotesEmail,
    quotesFirstName,
    quotesLastName,
    quotesPolicyState,
    quotesPolicyZipCode,
  ]);

  return (
    <div className={styles.container}>
      <form autoComplete='on' method='post'>
        <h4 className={styles.accountTitle}>Account Address</h4>

        <ShippingAddressForm
          address={shippingAddress}
          onChange={payload => dispatch(setUpdatedQuoteShippingField(payload))}
          addressData={addressData}
        />

        <div className={styles.mobileVisibility}>
          <PlanReview
            waitingPeriods={waitingPeriods}
            wellnessPets={wellnessPets}
            setWellnessPets={setWellnessPets}
          />

          <div className={styles.trustIcons}>
            <Trustpilot widgetType={'badge'} />
          </div>
        </div>

        <h4 style={{ margin: '60px 0 0' }}>Card Info</h4>

        <PaymentForm />

        <div className={styles.trustIcons}>
          <MoneyBackGuarantee policyState={quotesPolicyState} imgOnly={true} />
          <SslTrustIcon />
        </div>

        {showBillingAddress ? (
          <>
            <h4 style={{ margin: '60px 0 0' }}>Billing Address</h4>

            <BillingAddressForm
              address={billingAddress}
              isSameAsShipping={isShippingSameAsBilling}
              onAddressToggle={isChecked =>
                dispatch(
                  setUpdatedQuoteField({
                    field: 'isShippingSameAsBilling',
                    value: isChecked,
                  })
                )
              }
              canUseAccountAddress={!showBillingAddressAAP}
              onChange={payload => dispatch(setUpdatedQuoteBillingField(payload))}
            />
          </>
        ) : null}

        {showPaperless ? <Paperless /> : null}

        <Terms waitingPeriods={waitingPeriods} wellnessPets={wellnessPets} />

        <div ref={btnSectionRef} className={sectionRefClass} />

        <div className={buttonSectionClass}>
          <div className={buttonWrapperClass}>
            <PurchaseButton
              classes={{ root: styles.checkoutButton }}
              grandTotal={grandTotal}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

CheckoutForm.propTypes = {
  waitingPeriods: PropTypes.object,
  wellnessPets: PropTypes.array,
  setWellnessPets: PropTypes.func,
  addressData: PropTypes.object,
};

export default CheckoutForm;
