import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { WWW_URL } from '@pumpkincare/config';
import { getPlanVersion, isPrevent2 } from '@pumpkincare/plans';
import {
  getQuoteActivePet,
  getQuoteCompletePets,
  getQuotePets,
  getQuotePolicyState,
  useQuote,
  useQuotesActiveId,
} from '@pumpkincare/quotes';
import {
  formatCurrency,
  formatCustomerAgreementParams,
  getIsLoggedIn,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  Body2,
  Checkbox,
  LegalBody,
  Link,
  Modal,
  Typography,
} from '@pumpkincare/shared/ui';
import { getUserIsChargedAnnually, useUssr } from '@pumpkincare/user';

import {
  CHECKOUT_ERRORS,
  useErrors,
  useErrorsVisibility,
  useIsChargedAnnuallyValue,
  useTotalsValue,
} from '../../../checkout-provider';
import TermsPrevent2 from './terms-prevent-2';
import TermsPrevent3 from './terms-prevent-3';
import TermsStatic from './terms-static';

import styles from './terms.css';

function Terms({ waitingPeriods, wellnessPets }) {
  const { punks2110CaliforniaModelLaw } = useFlags();
  const { data: quoteData } = useQuote();
  const pets = getIsLoggedIn()
    ? getQuotePets(quoteData)
    : getQuoteCompletePets(quoteData);
  const { activeId } = useQuotesActiveId();
  const activePet = getQuoteActivePet(quoteData)(activeId);

  const errors = useErrors();
  const [errorsVisibility, setErrorsVisibility] = useErrorsVisibility();
  const [open, setOpen] = useBooleanInput(false);
  const { data: userData } = useUssr();

  const isLoggedUserAnnuallyCharged = getUserIsChargedAnnually(userData);
  const isLocalAnnuallyCharged = useIsChargedAnnuallyValue();

  const isChargedAnnually = getIsLoggedIn()
    ? isLoggedUserAnnuallyCharged
    : isLocalAnnuallyCharged;
  const frequency = isChargedAnnually ? 'year' : 'month';

  const { grandTotal } = useTotalsValue();
  const total = formatCurrency(grandTotal);

  const isErrorState =
    errorsVisibility &&
    (errors.current.termsChecked || errors.current.wellnessTermsChecked);

  const isCAModelLaw =
    punks2110CaliforniaModelLaw && getQuotePolicyState(quoteData) === 'CA';
  const hasWellnessQuotePet = !!wellnessPets?.length;

  function renderPrevent() {
    const petWithPlan = pets.find(pet => pet.plan);

    if (!petWithPlan) {
      return null;
    }

    const urlSearchString = formatCustomerAgreementParams(
      getPlanVersion(petWithPlan.plan)
    );

    if (isPrevent2(petWithPlan.plan)) {
      return <TermsPrevent2 urlSearchString={urlSearchString} />;
    } else {
      return <TermsPrevent3 urlSearchString={urlSearchString} />;
    }
  }

  function handleCheckboxChange() {
    toggleErrorState('termsChecked', CHECKOUT_ERRORS.termsChecked);
  }

  function handleWellnessCheckboxChange() {
    toggleErrorState('wellnessTermsChecked', CHECKOUT_ERRORS.wellnessTermsChecked);
  }

  function toggleErrorState(key, message) {
    if (errors.current[key]) {
      delete errors.current[key];
    } else {
      errors.current[key] = message;
    }

    setErrorsVisibility(false);
  }

  function handleInsuranceLinkClick(e) {
    e.preventDefault();
    setOpen();
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.headerSection}>
            <div className={styles.title}>
              <img src='/assets/images/iconImages/thumbtack.svg' alt='' />

              <h4 className={Typography.h4}>Just one more thing, human!</h4>
            </div>

            <div className={styles.titleImageWrapper}>
              <img
                className={styles.termsImage}
                alt='terms'
                src='/assets/images/cartoons/TermsConditions.png'
              />
            </div>
          </div>

          <div>
            <Body2>
              We get it’s not the most wag-worthy read, but these pet insurance
              policy details are important to understand upfront, so give them a
              read.
            </Body2>
          </div>

          <div className={styles.footerSection}>
            <Checkbox
              classes={{ root: styles.checkboxRoot }}
              onChange={handleCheckboxChange}
              label={
                <LegalBody>
                  {isCAModelLaw
                    ? 'I agree to purchase the Pumpkin Insurance plan(s) above, and accept '
                    : 'I have read and accept '}
                  <span
                    className={classNames(Typography.link, styles.linkText)}
                    onClick={handleInsuranceLinkClick}
                  >
                    the insurance policy terms and fraud notice
                  </span>
                  .
                </LegalBody>
              }
              data-testid={'accept-terms-checkbox'}
            />
            {isErrorState && errors.current.termsChecked ? (
              <LegalBody className={styles.error}>
                &nbsp;{errors.current.termsChecked}
              </LegalBody>
            ) : null}

            {isCAModelLaw && hasWellnessQuotePet && activePet.tier ? (
              <>
                <div className={styles.divider}></div>
                <Checkbox
                  classes={{ root: styles.checkboxRoot }}
                  onChange={handleWellnessCheckboxChange}
                  label={
                    <LegalBody>
                      {
                        'I agree to purchase the Pumpkin Wellness Club membership(s) above, and accept the '
                      }
                      <Link
                        href={`${WWW_URL}/sample-plan`}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        <span
                          className={classNames(Typography.link, styles.linkText)}
                        >
                          membership agreement
                        </span>
                      </Link>
                      .
                    </LegalBody>
                  }
                  data-testid={'accept-wellness-terms-checkbox'}
                />
              </>
            ) : null}
          </div>

          {isErrorState && errors.current.wellnessTermsChecked ? (
            <LegalBody className={styles.error}>
              &nbsp;{errors.current.wellnessTermsChecked}
            </LegalBody>
          ) : null}
        </div>

        <div className={styles.imageWrapper}>
          <img
            className={styles.termsImage}
            alt='terms'
            src='/assets/images/cartoons/TermsConditions.png'
          />
        </div>
      </div>

      <LegalBody className={styles.calloutGromit}>
        {isCAModelLaw && hasWellnessQuotePet ? (
          <>
            By clicking below, you authorize us to deduct the amount listed on this
            page at the selected frequency from the payment method you provided for
            the full payment of service when due. Payments for Pumpkin Insurance and
            Wellness Club plans will be processed as two separate transactions.
          </>
        ) : (
          <>
            By clicking below, you authorize us to deduct {total}/{frequency} from
            your provided payment method for the full payment of service when due.
          </>
        )}
      </LegalBody>
      {open ? (
        <Modal onClose={setOpen} classes={{ content: styles.modalContainer }}>
          <div className={styles.modalContent}>
            <TermsStatic waitingPeriods={waitingPeriods} />
            {renderPrevent()}
          </div>
        </Modal>
      ) : null}
    </>
  );
}

Terms.propTypes = {
  waitingPeriods: PropTypes.object,
  wellnessPets: PropTypes.array,
};

export default Terms;
