import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import Store from 'store';

import { IdentityService } from '@pumpkincare/identity';
import { QuotesService } from '@pumpkincare/quotes';

import { quotesReducer } from '../../quotes';
import segmentTracker from './segment-tracker';

export const history = createBrowserHistory();

export function rootReducer() {
  const pumpkinReducer = combineReducers({
    quotes: quotesReducer,
    router: connectRouter(history),
  });

  return (state, action) => {
    if (action.type === 'app/RESET') {
      state = {
        router: state.router,
      };
    }

    return pumpkinReducer(state, action);
  };
}

export function createQuoteFlowStore(initialState, extraArgument) {
  const reducer = rootReducer(history);

  const middleware = composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      segmentTracker,

      thunk.withExtraArgument({
        Store,
        identityService: IdentityService,
        quotesService: QuotesService,
        ...extraArgument,
      })
    )
  );

  return createStore(reducer, initialState, middleware);
}
