import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDebouncedCallback } from 'use-debounce';

import {
  analyticsTrack,
  GAQuoteFlowClickHowWillWeUseThis,
  INITIATE_EXPERIMENT,
} from '@pumpkincare/analytics';
import { MC_URL, WWW_URL } from '@pumpkincare/config';
import {
  getDiscountId,
  useDiscount,
  validateDiscountByZipCode,
} from '@pumpkincare/discounts';
import {
  getQuoteEmail,
  getQuoteFirstName,
  getQuoteLastName,
  getQuotePolicyZipCode,
  getQuoteVet,
  useQuote,
} from '@pumpkincare/quotes';
import {
  captureException,
  formatPhoneNumber,
  GENERIC_ERROR,
  requestZipCodeValidity,
  useBanners,
  validateEmail,
} from '@pumpkincare/shared';
import {
  LegalBody,
  TextField,
  Typography,
  ZipcodeField,
} from '@pumpkincare/shared/ui';
import { postCheckEmail } from '@pumpkincare/user';
import { VetAttribution } from '@pumpkincare/vets/ui';

import QuoteFlowHeader from '../quote-flow-header';
import { NextButton, QuoteFlowComponentContent } from '../shared';
import useSubmitRegister from './use-submit-register';

import styles from './register.css';

// https://petlife.atlassian.net/browse/PUNKS-1324
// technically partner is only embark in production,
// so no user should be coming from /teams with embark code.
const discountBandaidStates = {
  HI: ['vet', 'employer', 'partner'],
  MN: ['vet', 'partner'],
  TN: ['vet', 'employer', 'partner'],
  NY: ['vet'],
  FL: ['employer', 'partner'],
};

function Register() {
  const { data: quoteData, isSuccess: isQuoteSuccess } = useQuote();
  const quotePolicyZipCode = getQuotePolicyZipCode(quoteData);
  const quoteEmail = getQuoteEmail(quoteData);
  const quoteFirstName = getQuoteFirstName(quoteData);
  const quoteLastName = getQuoteLastName(quoteData);
  const quoteVet = getQuoteVet(quoteData);

  const { data: discountData } = useDiscount();
  const discountId = getDiscountId(discountData);

  const { addBanner, removeAllBanners } = useBanners();
  const { submit: submitRegister, isLoading } = useSubmitRegister();

  const { mvx144PhoneInQf, fer5659ExamDayDiscount } = useFlags();

  const [firstName, setFirstName] = useState(quoteFirstName ?? '');
  const [lastName, setLastName] = useState(quoteLastName ?? '');
  const [email, setEmail] = useState(quoteEmail ?? '');
  const [policyZipCode, setPolicyZipCode] = useState(quotePolicyZipCode ?? '');
  const [phone, setPhone] = useState('');
  const vet = useRef(quoteVet);
  const shouldSubmitDiscount = useRef(true);
  const [showHowWeUseInfo, setShowHowWeUseInfo] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [errorMessages, setErrorMessages] = useState({});
  const [canSubmitVet, setCanSubmitVet] = useState(false);
  const [zipDisabled, setZipDisabled] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(null);

  const [isRequestingZipCodeValidity, setIsRequestingZipCodeValidity] =
    useState(false);
  const [isValidatingDiscountByZipCode, setIsValidatingDiscountByZipCode] =
    useState(false);

  const isValidEmail = validateEmail(email);
  const isEmailInputValid = !!email && isValidEmail && !emailError;
  const isZipCodeInputValid = !!policyZipCode && zipCodeError === false;

  useEffect(() => {
    if (typeof mvx144PhoneInQf === 'boolean') {
      analyticsTrack({
        event: INITIATE_EXPERIMENT,
        category: 'Quote Flow Phone',
        label: mvx144PhoneInQf ? 'b_test' : 'a_control',
      });
    }
  }, [mvx144PhoneInQf]);

  function hasExamDayDiscount() {
    return fer5659ExamDayDiscount && discountId && discountData?.org_type === 'exam';
  }

  function validateExamDiscountAndZipCode() {
    return (
      hasExamDayDiscount() && isZipCodeInputValid && shouldSubmitDiscount.current
    );
  }

  function validateExamDayDiscount() {
    if (hasExamDayDiscount()) {
      if (isRequestingZipCodeValidity || isValidatingDiscountByZipCode) {
        return false;
      }

      if (isZipCodeInputValid && shouldSubmitDiscount.current) {
        const isVetEmpty = !vet.current?.id;
        return !isVetEmpty;
      }
    }

    return true;
  }

  const isValidExamDayDiscount = validateExamDayDiscount();
  const showMessageRequiredVetClinic =
    hasExamDayDiscount() &&
    !isValidExamDayDiscount &&
    !isRequestingZipCodeValidity &&
    !isValidatingDiscountByZipCode;

  const canSubmit =
    firstName &&
    lastName &&
    isEmailInputValid &&
    isZipCodeInputValid &&
    isLoading === false &&
    canSubmitVet &&
    isValidExamDayDiscount;

  const invalidZipError =
    'Hmmm, we can’t find that one. Please enter a valid U.S. zip code.';
  const ineligibleDiscount =
    'Unfortunately, due to state regulations, you are not eligible for a group discount. Don’t worry you are still eligible for coverage.';

  const debouncedValidateEmail = useDebouncedCallback(value => {
    postCheckEmail(value)
      .then(({ exists }) => {
        setEmailError(
          exists ? (
            <span>
              Looks like you already have a Pumpkin account. Please{' '}
              <a href={MC_URL} className={styles.logIn}>
                log in
              </a>{' '}
              and visit the "My Pet's Plan" page to add another pet.
            </span>
          ) : (
            ''
          )
        );
      })
      .catch(() => {
        setEmailError('Unable to verify email');
      });
  }, 500);

  function resolveCatchValidateDiscountByZipCode(error) {
    shouldSubmitDiscount.current = false;

    switch (error.response.status) {
      case 400: {
        setErrorMessages(state => ({
          ...state,
          zipcode: invalidZipError,
        }));

        break;
      }
      case 404: {
        setErrorMessages(state => ({
          ...state,
          zipcode: 'Discount not found.',
        }));

        break;
      }
      case 422: {
        setErrorMessages(state => ({
          ...state,
          zipcode: ineligibleDiscount,
        }));

        break;
      }
      default: {
        setErrorMessages(state => ({
          ...state,
          zipcode: 'Generic error.',
        }));

        break;
      }
    }
  }

  function resolveValidateDiscountByZipCode(state, discount) {
    if (
      discountBandaidStates[state]?.find(override => override === discount.org_type)
    ) {
      setErrorMessages(state => ({
        ...state,
        zipcode: ineligibleDiscount,
      }));
      shouldSubmitDiscount.current = false;
    } else {
      shouldSubmitDiscount.current = true;
    }
  }

  const debouncedValidateZipCode = useDebouncedCallback(value => {
    setIsRequestingZipCodeValidity(true);
    setIsValidatingDiscountByZipCode(true);

    requestZipCodeValidity(value)
      .then(({ state, valid }) => {
        setZipCodeError(false);
        if (!valid && !state) {
          setErrorMessages(state => ({ ...state, zipcode: invalidZipError }));
        } else if (discountId) {
          validateDiscountByZipCode(discountId, value)
            .then(discount => {
              resolveValidateDiscountByZipCode(state, discount);
            })
            .catch(error => {
              resolveCatchValidateDiscountByZipCode(error);
            })
            .finally(() => {
              setIsValidatingDiscountByZipCode(false);
            });
        } else {
          setErrorMessages(state => ({ ...state, zipcode: null }));
          setIsValidatingDiscountByZipCode(false);
        }
      })
      .catch(() => {
        setZipCodeError(true);
        setErrorMessages(state => ({ ...state, zipcode: invalidZipError }));
      })
      .finally(() => {
        setIsRequestingZipCodeValidity(false);
      });
  }, 500);

  function handleVetClinicChange(data) {
    vet.current = data;
    setCanSubmitVet(!!vet.current?.vet_name);
  }

  function handleHasVetChange(value) {
    vet.current = {};
    if (validateExamDiscountAndZipCode()) {
      setCanSubmitVet(value ? !!vet.current?.id : false);
    } else {
      setCanSubmitVet(value ? !!vet.current?.vet_name : true);
    }
  }

  function handleFirstNameChange({ target: { value } }) {
    setFirstName(value);
  }

  function handleLastNameChange({ target: { value } }) {
    setLastName(value);
  }

  function handleEmailChange({ target: { value } }) {
    setEmailError('');
    setEmail(value);

    if (validateEmail(value)) {
      debouncedValidateEmail(value);
    }
  }

  function handleOnBlurEmail({ target: { value } }) {
    if (value) {
      if (value.includes(' ')) {
        setEmailError('Email should not contain spaces.');
      } else if (!validateEmail(value)) {
        setEmailError('Invalid email format.');
      }
    }
  }

  function handlePhoneChange({ target: { value } }) {
    setPhone(formatPhoneNumber(value));
  }

  function handleZipCodeChange({ target: { value } }) {
    setZipCodeError(null);
    setPolicyZipCode(value);
    setErrorMessages(state => ({ ...state, zipcode: null }));

    if (value.length === 5) {
      debouncedValidateZipCode(value);
    }
  }

  function handleNextClick() {
    removeAllBanners();
    setZipDisabled(true);

    submitRegister({
      email: email.toLowerCase(),
      firstName,
      lastName,
      policyZipCode,
      phone,
      vet: vet.current,
      shouldSubmitDiscount: shouldSubmitDiscount.current,
    }).catch(err => {
      captureException(err);

      addBanner(GENERIC_ERROR);
    });
  }

  function handleShowHowWeUseInfo(e) {
    e.preventDefault();

    GAQuoteFlowClickHowWillWeUseThis();

    setShowHowWeUseInfo(!showHowWeUseInfo);
  }

  return isQuoteSuccess ? (
    <>
      <QuoteFlowHeader className={styles.headerTitle}>
        One last thing! Let’s get your info, human.
      </QuoteFlowHeader>

      <QuoteFlowComponentContent className={styles.register}>
        <LegalBody className={styles.headerText}>
          This info can’t be edited at checkout, so make sure you enter it
          prrrfectly!
        </LegalBody>

        <TextField
          label={'Your First Name'}
          id={'first-name'}
          onChange={handleFirstNameChange}
          value={firstName}
          data-testid={'first-name-input'}
          classes={{ container: styles.textField }}
          required
          autoFocus
        />

        <TextField
          label={'Your Last Name'}
          id={'last-name'}
          aria-label={'Your Last Name'}
          onChange={handleLastNameChange}
          value={lastName}
          data-testid={'last-name-input'}
          classes={{ container: styles.textField }}
          required
        />

        <TextField
          label={'Email Address'}
          id={'email'}
          onChange={handleEmailChange}
          onBlur={handleOnBlurEmail}
          error={{
            hasError: !(isEmailInputValid || email === ''),
            errorMessage: emailError,
          }}
          value={email}
          data-testid={'email-input'}
          classes={{ container: styles.textField }}
          required
        />

        {mvx144PhoneInQf ? (
          <TextField
            label='Phone Number (Optional)'
            placeholder='(123) 456-7890'
            id='phone'
            onChange={handlePhoneChange}
            value={phone}
            data-testid={'phone-input'}
            classes={{ container: styles.textField }}
          />
        ) : null}

        <ZipcodeField
          label={'Home Zip Code'}
          id={'zipCode'}
          placeholder={'Home Zip Code'}
          onChange={handleZipCodeChange}
          readOnly={zipDisabled}
          value={policyZipCode}
          error={{
            hasError: !(isZipCodeInputValid || policyZipCode === ''),
          }}
          classes={{ container: styles.textField }}
          data-testid={'zipcode-input'}
          required
        />

        {showMessageRequiredVetClinic ? (
          <p className={classNames(Typography.legalBody, styles.error)}>
            It will be necessary to inform the vet hospital for a group discount.
          </p>
        ) : null}

        {Object.values(errorMessages).map(errMsg => {
          return errMsg ? (
            <p
              key={errMsg}
              className={classNames(Typography.legalBody, styles.error)}
            >
              {errMsg}
            </p>
          ) : null;
        })}

        <VetAttribution
          disabled={!isZipCodeInputValid}
          zipcode={policyZipCode}
          selectedVet={vet.current}
          onVetClinicChange={handleVetClinicChange}
          onHasVetChange={handleHasVetChange}
        />

        <div className={styles.howUseWrapper}>
          {!mvx144PhoneInQf ? (
            <span className={styles.howUse}>
              <img alt='info' src='/assets/images/info.svg' />
              <span
                onClick={handleShowHowWeUseInfo}
                data-event='Click How will we use this?'
                data-category='Quote Flow'
                className={classNames(Typography.legalBody, styles.howUseLink)}
              >
                How will we use this?
              </span>
            </span>
          ) : null}

          {showHowWeUseInfo ? (
            <p className={classNames(Typography.legalBody, styles.howUseBlurb)}>
              Sharing your contact information allows us to provide you with a quote
              based on your location. It also allows us to retrieve a past quote for
              you if you decide you want to purchase at a later date.
            </p>
          ) : null}
        </div>
      </QuoteFlowComponentContent>

      <NextButton
        disabled={!canSubmit}
        onClick={handleNextClick}
        isLoading={isLoading}
        style={{ marginTop: '24px' }}
      />

      {mvx144PhoneInQf ? (
        <>
          <p className={classNames(Typography.legalBody, styles.disclaimer)}>
            Sharing your contact information allows us to provide you with a quote
            based on your location. It also allows us to retrieve a past quote for
            you if you decide you want to purchase at a later date.
          </p>

          <p className={classNames(Typography.legalBody, styles.disclaimer)}>
            By clicking ‘Next’ you consent for us to contact you about Pumpkin Pet
            Insurance services via email, text, and phone calls to the contact
            information provided, including using automated technology. Phone number
            is not required to start quote. Your information is collected and used in
            accordance with Pumpkin’s{' '}
            <a
              href={`${WWW_URL}/privacy-center/privacy-policy`}
              target='_blank'
              style={{ fontSize: 'inherit' }}
              rel='noreferrer'
            >
              Privacy Policy
            </a>
            {''}, and you may opt out any time.
          </p>
        </>
      ) : null}
    </>
  ) : null;
}

export default Register;
