const Paths = {
  Error: '/error',
  Maintenance: '/maintenance',
  MaintenanceFrame: '/maintenance-frame',

  EmbarkHello: '/quote/embark-hello',
  Intro: '/quote/intro',
  PetInfo: '/quote/pet-info-1',
  PetInfoInterstitial: '/quote/pet-info-2',
  PetQuestions: '/quote/pet-questions',
  QuoteFlow: '/quote',
  Register: '/quote/register',
  Teams: '/teams',

  Checkout: '/checkout',
  CheckoutDeepLink: '/checkout/:quoteId',
  VetCollection: '/vet-collection',
  CheckYourEmail: '/check-your-email',
  PlanSelection: '/plan-selection',
  PlanSelectionDeepLink: '/plan-selection/:quoteId',
  Hdyhau: '/hdyhau',
  PumpkinNowQuestion: '/hdyhau-pumpkin-now',
};

export default Paths;
