import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Store from 'store';

import {
  getProductCanUpsellPrevent,
  isPrevent4,
  mixedPets,
  parsePlanDetails,
  usePlan,
  useProductsByState,
} from '@pumpkincare/plans';
import { getQuotePets, getQuotePolicyState, useQuote } from '@pumpkincare/quotes';
import {
  ARF_MEOW_PHONE_NUMBER,
  COMPLETE_QUOTE_ID_LS_KEY,
  FORMATTED_PHONE_NUMBER,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { LegalBody, Link } from '@pumpkincare/shared/ui';

import styles from './pep-upsell.css';

function PepUpsell() {
  const { mvx218MultiTouchHdyhau } = useFlags();
  const quoteId = Store.get(COMPLETE_QUOTE_ID_LS_KEY);

  const { data: quoteData, isLoading } = useQuote({
    quoteId,
  });
  const quotePets = getQuotePets(quoteData);

  const { data: planData } = usePlan(
    quotePets[0]?.petAge,
    quotePets[0]?.petBreedSpecies,
    null,
    quoteData.vet.id
  );
  const policyState = getQuotePolicyState(quoteData);
  const { data: productsByStateData } = useProductsByState(policyState);
  const productCanUpsellPrevent = getProductCanUpsellPrevent(productsByStateData);

  const [isMixed, setIsMixed] = useState(false);
  const [vetHospName, setVetHospName] = useState();
  const [hideUpsell, setHideUpsell] = useState(false);
  const planDetails =
    planData && !isMixed
      ? parsePlanDetails(planData, quotePets[0].petBreedSpecies)
      : [
          {
            item: '1 Annual Wellness Exam',
          },
          {
            item: 'Vaccines at the Vet: 1 per cat or kitten, 4 per puppy, 2 per adult dog',
          },
          {
            item: '1 Fecal "Poop" Test for Intestinal Worms',
          },
        ];

  useEffect(() => {
    if (!isLoading) {
      setIsMixed(mixedPets(quotePets));

      // State does not allow PEP
      if (!productCanUpsellPrevent) {
        setHideUpsell(true);
      } else {
        // Hide upsell only if ALL quotepets bought pep
        setHideUpsell(quotePets.every(qP => qP.preventativeSelected === true));
      }

      if (quoteData.vet.vet_name) {
        setVetHospName(quoteData.vet.vet_name);
      }
    }
  }, [isLoading, quotePets, quoteData, productCanUpsellPrevent]);

  return isLoading ? null : hideUpsell ? (
    <div
      className={classNames(styles.celebrationContainer, {
        [styles.multiTouch]: mvx218MultiTouchHdyhau,
      })}
    >
      <img
        className={styles.celebrationImg}
        src='/assets/images/hdyhau/celebration.png'
        alt=''
      />
    </div>
  ) : (
    <div
      className={classNames(styles.container, {
        [styles.multiTouch]: mvx218MultiTouchHdyhau,
      })}
    >
      <div className={styles.content}>
        <div className={styles.helpTitle}>
          <h4 className={styles.header}>
            The right time to add Preventive Essentials to your plan is right now!
          </h4>
        </div>

        <LegalBody className={styles.bodyText}>
          <span>
            Insurance alone won’t solve your pet’s health & wellness needs. It’s why
            we worked with {planData && isPrevent4(planData) ? vetHospName : 'vets'}{' '}
            to create a unique wellness package called Preventive Essentials. It’s
            not insurance, but an optional benefit you can add to your plan if you
            want refunds for key wellness services vets recommend.{' '}
            {isMixed ? 'Call to learn more about what’s in this package!' : null}
          </span>
        </LegalBody>

        {planData && isPrevent4(planData) && isMixed ? null : (
          <div className={styles.itemsBlock}>
            {planDetails.map(({ item }) => (
              <LegalBody key={item} className={styles.optionItems}>
                <div className={styles.iconContainer}>
                  <img
                    alt=''
                    className={styles.icon}
                    src='/assets/images/iconImages/check-white.svg'
                  />
                </div>

                <span>{item}</span>
              </LegalBody>
            ))}
          </div>
        )}

        <div>
          <LegalBody className={styles.helpText}>
            <LegalBody isBold className={styles.boldText}>
              Ready for refunds on routine care?
            </LegalBody>

            <span>Call our care team at</span>

            <Link href={PHONE_NUMBER_LINK}>
              <LegalBody
                isBold
                className={styles.linkColor}
              >{`${ARF_MEOW_PHONE_NUMBER} (${FORMATTED_PHONE_NUMBER})`}</LegalBody>
            </Link>

            <span>to add Preventive Essentials to your plan.</span>
          </LegalBody>
        </div>
      </div>

      <div className={styles.imgPosition}>
        <img
          src='/assets/images/hdyhau/pep.png'
          alt='Already had their vaccines? It’s not too late to get a refund! Enroll in Preventive Essentials within 14 days to get a bowlful back on these wellness services.'
          className={styles.imgSize}
        />
      </div>
    </div>
  );
}

export default PepUpsell;
