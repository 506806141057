import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Store from 'store';

import {
  analyticsTrack,
  getAnonymousId,
  getTrackedCustomProps,
  QUOTE_FLOW,
} from '@pumpkincare/analytics';
import { MC_URL } from '@pumpkincare/config';
import { patchIdentity } from '@pumpkincare/identity';
import { setMarketingAttributionProperty } from '@pumpkincare/marketing';
import {
  getQuoteId,
  getQuoteIdentityId,
  getQuotePet,
  getQuotePets,
  getQuoteTrackingId,
  getWellnessQuotePets,
  patchQuoteTracking,
  QUOTE_ID_COOKIE_NAME,
  transformFinalize,
  useFinalize,
  useQuote,
  useQuotesActiveId,
  useWellnessQuote,
} from '@pumpkincare/quotes';
import {
  ARF_MEOW_PHONE_NUMBER,
  BannerType,
  COMPLETE_QUOTE_ID_LS_KEY,
  CONTACT_EMAIL,
  currentISODate,
  DUPLICATE_PET_ERROR,
  GENERIC_CONTACT_ERROR,
  GENERIC_PAYMENT_ERROR,
  getCookie,
  getIsLoggedIn,
  getStripeError,
  IDENTITY_LOCAL_STORAGE_KEY,
  ITERABLE_CAMPAIGN_ID,
  ITERABLE_TEMPLATE_ID,
  navigateTo,
  PARTNER_VET_MODAL_PRESENTED,
  postBillingAddressValidate,
  postRatingAddressValidate,
  QUOTE_URL_PARAMS_KEY,
  removeCookie,
  SPRIG_SURVEY_PRESENTED,
  useBanners,
} from '@pumpkincare/shared';
import {
  getPaymentMethodLast4,
  getUserId,
  getUserIsChargedAnnually,
  isAddressComplete,
  postOnboardingSteps,
  usePayment,
  useUserAddresses,
  useUssr,
} from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import { resetQuotes } from '../../../quotes';
import {
  getQuotes,
  getQuotesBillingAddress,
  getQuotesShippingAddress,
  getShippingSameAsBilling,
} from '../../../quotes/selectors';
import {
  useIsChargedAnnuallyValue,
  usePaperLessValue,
  useTotalsValue,
} from '../checkout-provider';
import { addressReduxTransform } from './checkout-utils';

export default function useSubmitCheckout() {
  const { punks1954UpsellSawToSawIns, mvx104McOnboardingModal } = useFlags();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const dispatch = useDispatch();
  const billingAddress = useSelector(getQuotesBillingAddress);
  const quotes = useSelector(getQuotes);
  const shippingAddress = useSelector(getQuotesShippingAddress);
  const isSameAsShipping = useSelector(getShippingSameAsBilling);
  const { data: addressData } = useUserAddresses({ enabled: getIsLoggedIn() });

  const { addBanner, removeAllBanners } = useBanners();
  const { grandTotal } = useTotalsValue();
  const { data: userData } = useUssr();
  const isChargedAnnually = useIsChargedAnnuallyValue();
  const { termsVersion, isPaperLess } = usePaperLessValue();
  const [isLoading, setIsLoading] = useState(false);

  const { data: paymentData } = usePayment();
  const last4 = getPaymentMethodLast4(paymentData);

  const { data: quoteData } = useQuote();
  const { activeId } = useQuotesActiveId();
  const activePet = getQuotePet(quoteData)(activeId);
  const pets = getQuotePets(quoteData);
  const quoteId = getQuoteId(quoteData);
  const identityId =
    getQuoteIdentityId(quoteData) || Store.get(IDENTITY_LOCAL_STORAGE_KEY);

  const [wellnessPets, setWellnessPets] = useState([]);
  useWellnessQuote({
    onSuccess: data => {
      setWellnessPets(getWellnessQuotePets(data));
    },
  });

  const { mutateAsync: finalize } = useFinalize();

  function hasQuotePaymentInfo(paymentInfo) {
    if (!paymentInfo) {
      return false;
    }

    const fieldsToCheck = ['nameShownOnCard', 'cardNumber', 'expirationDate', 'cvc'];

    return fieldsToCheck.every(field => paymentInfo[field].empty === false);
  }

  function submitCheckout() {
    const iterableCampaignId = getCookie(ITERABLE_CAMPAIGN_ID);
    const iterableTemplateId = getCookie(ITERABLE_TEMPLATE_ID);

    patchIdentity({
      id: identityId,
      city: shippingAddress.city.value,
      state_province: shippingAddress.state.value,
      zipcode: shippingAddress.zipcode.value,
      first_name: shippingAddress.firstName.value,
      last_name: shippingAddress.lastName.value,
      phone_number: shippingAddress.phone.value,
    });

    // Prepare token creation and address validation promises
    const tokenPromise = !last4
      ? stripe.createToken(elements.getElement(CardNumberElement)).then(payload => {
          analyticsTrack(
            {
              category: QUOTE_FLOW,
              event: 'Submit Payment',
              label: payload.error ? 'Failed' : 'Success',
            },
            getTrackedCustomProps()
          );

          return payload.error ? Promise.reject() : payload.token.id;
        })
      : Promise.resolve(null);

    const addressValidationPromises =
      !last4 && !hasQuotePaymentInfo(quotes?.paymentInfo)
        ? [
            postRatingAddressValidate(
              addressReduxTransform({ country: { value: 'US' }, ...shippingAddress })
            ),
            postBillingAddressValidate(
              addressReduxTransform(
                isSameAsShipping
                  ? { country: { value: 'US' }, ...shippingAddress }
                  : billingAddress
              )
            ),
          ]
        : [Promise.resolve(null), Promise.resolve(null)];

    return Promise.all([tokenPromise, ...addressValidationPromises])
      .then(([stripeToken]) => {
        const postBody = last4
          ? { termsVersion, isPaperLess, isChargedAnnually }
          : transformFinalize(
              stripeToken,
              quotes,
              isChargedAnnually,
              wellnessPets,
              termsVersion,
              isPaperLess
            );

        if (isAddressComplete(addressData.rating)) {
          postBody.shipping_information = {
            ...addressData.rating,
            state: quotes.policy_state,
          };
        }

        if (isAddressComplete(addressData.billing)) {
          postBody.billing_information = {
            ...addressData.billing,
            state: quotes.policy_state,
            first_name: quotes.firstName,
            last_name: quotes.lastName,
            name_on_card: quotes.paymentInfo.nameShownOnCard.value,
          };
        }

        return finalize({ quoteId, postBody });
      })
      .then(result => {
        const {
          token: { access_token },
          user,
        } = result;
        const { email, id: userId } = user;

        // Track order completion
        trackOrderCompletion(email, iterableCampaignId, iterableTemplateId);

        // Clean up, async actions and navigate
        cleanUpAfterCheckout();
        if (mvx104McOnboardingModal)
          postOnboardingSteps(userId, quoteId).catch(() => {});
        navigateToNextPage(access_token, email);
      });
  }

  // Function to track order completion
  function trackOrderCompletion(email, campaignId, templateId) {
    analyticsTrack({
      event: 'Order Completed',
      email,
      total: parseFloat(grandTotal),
      campaignId: campaignId ? parseInt(campaignId) : campaignId,
      templateId: templateId ? parseInt(templateId) : templateId,
      dataFields: {},
    });

    analyticsTrack(
      {
        category: 'Purchase Event',
        event: 'Finalize Success',
        label: `Policies: ${pets.length}, Plans: ${
          pets.filter(pet => !!pet.hasPrevent).length
        }`,
        created_at: currentISODate(),
      },
      getTrackedCustomProps()
    );

    setMarketingAttributionProperty({ isGoingToCheckoutSuccess: true });
  }

  // Function to clean up after checkout
  function cleanUpAfterCheckout() {
    removeAllBanners();
    dispatch(resetQuotes());
    Store.remove(QUOTE_URL_PARAMS_KEY);
    Store.set(COMPLETE_QUOTE_ID_LS_KEY, getCookie(QUOTE_ID_COOKIE_NAME));
    removeCookie(QUOTE_ID_COOKIE_NAME);
  }

  // Function to navigate to the next page
  function navigateToNextPage(access_token, email) {
    if (activePet.existing_pet_id) {
      navigateTo(MC_URL);
    } else {
      history.push(
        Paths.Hdyhau +
          '?' +
          new URLSearchParams({ email, token: access_token }).toString()
      );
    }
  }

  function hasValidBillingInfo(billingInformation) {
    for (let key in billingInformation) {
      // Check if any key has an empty value
      if (billingInformation[key] === '') {
        return false; // If any value is empty, return false
      }
    }
    return true; // If all values are non-empty, return true
  }

  function submitCheckoutApld() {
    const isLoggedUserAnnuallyCharged = getUserIsChargedAnnually(userData);
    const postBody = {
      billed_annually: isLoggedUserAnnuallyCharged,
      ...(hasValidBillingInfo(addressReduxTransform(billingAddress))
        ? { billing_information: addressReduxTransform(billingAddress) }
        : {}),
    };

    function finalizeQuoteProcessing() {
      Store.set(COMPLETE_QUOTE_ID_LS_KEY, getCookie(QUOTE_ID_COOKIE_NAME));
      Store.remove(SPRIG_SURVEY_PRESENTED);
      Store.remove(PARTNER_VET_MODAL_PRESENTED);
      removeCookie(QUOTE_ID_COOKIE_NAME);

      dispatch(resetQuotes());

      navigateTo(MC_URL);
    }

    return finalize({
      quoteId,
      postBody,
    }).then(() => {
      if (mvx104McOnboardingModal) {
        postOnboardingSteps(getUserId(userData), quoteId)
          .catch(() => {})
          .finally(finalizeQuoteProcessing);
      } else {
        finalizeQuoteProcessing();
      }
    });
  }

  function getFinalizeErrorMessage(error) {
    const errorMessage = error?.response?.data?.message;

    const errorMap = {
      invalid_pet_parent: 'Invalid First Name or Last Name',
      missing_first_last_names: 'Please enter a valid First/Last Name',
      identity_not_found: `Try creating another quote or contacting our care team at ${CONTACT_EMAIL} or at ${ARF_MEOW_PHONE_NUMBER}.`,
    };

    const normalizedErrorMessage = errorMessage?.toLowerCase();

    for (const key in errorMap) {
      if (normalizedErrorMessage.includes(key)) {
        return errorMap[key];
      }
    }

    // Default empty if no match is found
    return '';
  }

  function submit() {
    setIsLoading(true);

    const trackingId = getQuoteTrackingId(quoteData) || getAnonymousId();
    patchQuoteTracking({ quoteId, trackingId });

    const isExistingPet = punks1954UpsellSawToSawIns && activePet.existing_pet_id;

    return (
      getIsLoggedIn() && !isExistingPet ? submitCheckoutApld() : submitCheckout()
    )
      .catch(error => {
        removeAllBanners();
        if (error?.response?.status === 400) {
          const stripeError = getStripeError(error);
          const httpErrorMessage = getFinalizeErrorMessage(error);
          const errorMessage = stripeError || httpErrorMessage;

          if (errorMessage) {
            addBanner({
              type: BannerType.ERROR,
              title: 'We are unable to process your payment.',
              message: errorMessage,
            });
          } else {
            addBanner(GENERIC_CONTACT_ERROR);
          }
        } else if (error?.response?.status === 409) {
          addBanner(DUPLICATE_PET_ERROR);
        } else if (error?.response?.status === 422) {
          const errors = error.response.data?.errors
            ? Object.values(error.response.data.errors)[0]
            : 'Invalid address';

          addBanner({
            type: BannerType.ERROR,
            title: `${
              error.config.url.includes('rating') ? 'Account' : 'Billing'
            } Address Error:`,
            message: errors,
          });
        } else {
          addBanner(GENERIC_PAYMENT_ERROR);
        }
      })
      .finally(() => {
        window.scrollTo(0, 0);
        return setIsLoading(false);
      });
  }

  return { submit, isLoading };
}
