import { useRef } from 'react';
import PropTypes from 'prop-types';

import { analyticsTrack } from '@pumpkincare/analytics';
import { Body1, LoaderButton, Radio, Select } from '@pumpkincare/shared/ui';
import { VetClinic } from '@pumpkincare/vets/ui';

import useSubmitHdyhau from '../use-submit-hdyhau';

import styles from './hdyhau-legacy.css';

const MY_VET_CLINIC = 'MY_VET_CLINIC';
const OPTIONS = [
  { value: MY_VET_CLINIC, label: 'My Vet Clinic' },
  { value: 'MY_EMPLOYER', label: 'My Employer' },
  { value: 'BREEDER_OR_SHELTER', label: 'Breeder/Shelter' },
  { value: 'GOOGLE_OR_AD', label: 'Google/Search Ad' },
  { value: 'SOCIAL_MEDIA', label: 'Social Media' },
  { value: 'TELEVISION', label: 'Television' },
  { value: 'FRIEND_OR_FAMILY', label: 'Friend/Family' },
  { value: 'SNAIL_MAIL', label: 'Snail Mail' },
  { value: 'PET_INSURANCE_REVIEW_SITE', label: 'Pet Insurance Review Site' },
];
const OPTION_OTHER = { value: 'OTHER', label: 'Other' };

function HdyhauLegacy({
  quoteZipCode,
  isCTADisabled,
  selectedVet,
  handleVetSelect,
  hdyhauOptionSelected,
  setHdyhauOptionSelected,
  vetId,
}) {
  const sortedOptions = useRef([
    ...OPTIONS.sort(() => Math.random() - 0.5), // NOSONAR

    OPTION_OTHER,
  ]);

  const { submit: submitHdyhau, isLoading } = useSubmitHdyhau({ vetId });

  function handleRadioClick(event) {
    setHdyhauOptionSelected(event.target.value);
  }

  function handleSelectClick(event) {
    setHdyhauOptionSelected(event?.value);
  }

  function handleNextClick() {
    let order = 0;
    const hdyhauOptionValue = sortedOptions.current.find(option => {
      order++;
      return option.value === hdyhauOptionSelected;
    }).label;

    analyticsTrack({
      category: 'Vet Form',
      event: 'Submit HDYHAU',
      answer: hdyhauOptionValue,
      order,
    });

    submitHdyhau({
      vet: selectedVet?.existing,
      hdyhauOptionSelected,
      hdyhauOptionValue,
      order,
    });
  }

  return (
    <>
      <Body1 isBold className={styles.question}>
        First a quick question...
        <br />
        How did you hear about Pumpkin?
      </Body1>

      <div className={styles.checkbox}>
        {sortedOptions.current.map(option => (
          <div key={option.value} className={styles.checkboxItem}>
            <Radio
              small
              key={option.value}
              value={option.value}
              label={<Body1 className={styles.optionLabel}>{option.label}</Body1>}
              classes={{
                root: styles.radio,
              }}
              onChange={handleRadioClick}
              checked={option.value === hdyhauOptionSelected}
            />
          </div>
        ))}
      </div>

      <div className={styles.select}>
        <Select
          isSearchable
          onChange={e => handleSelectClick(e)}
          placeholder={
            hdyhauOptionSelected
              ? sortedOptions.current.find(obj => obj.value === hdyhauOptionSelected)
                  .label
              : 'Select how you heard of us!'
          }
          options={sortedOptions.current}
          defaultValue={hdyhauOptionSelected}
          id={'id-select-option'}
          autoFocus
        />
      </div>

      {hdyhauOptionSelected === MY_VET_CLINIC ? (
        <VetClinic
          className={styles.vetClinic}
          onChange={handleVetSelect}
          zipcode={quoteZipCode}
        />
      ) : null}

      <LoaderButton
        disabled={isCTADisabled}
        classes={{ root: styles.ctaButton }}
        color={'primary'}
        onClick={handleNextClick}
        data-testid='submit-button'
        isLoading={isLoading}
      >
        Next
      </LoaderButton>
    </>
  );
}

HdyhauLegacy.propTypes = {
  quoteZipCode: PropTypes.string.isRequired,
  isCTADisabled: PropTypes.bool.isRequired,
  selectedVet: PropTypes.object,
  handleVetSelect: PropTypes.func.isRequired,
  hdyhauOptionSelected: PropTypes.string,
  setHdyhauOptionSelected: PropTypes.func.isRequired,
  vetId: PropTypes.string,
};

export default HdyhauLegacy;
